import { Controller } from "@hotwired/stimulus";
import { useMemo } from "stimulus-use";

export default class extends Controller {
  static values = {
    defaultList: {
      type: Array,
      default: []
    },
    offeringType: {
      type: String,
      default: ""
    }
  }

  static memos = [
    'offeringInput',
    'descriptionInput',
    'priceInput',
    'costInput'
  ]

  connect() {
    useMemo(this);
  
    if (!this.offeringInput.value) { return }

    this._setPersistedOffering()
    this.populate()
  }

  populate(event) {
    let offering;
    const selectedOffering = this._findOfferingBy(this._getIdFromGlobalId())

    if (this._selectedIsPersisted()) {
      offering = this.persistedOffering
    } else {
      offering = selectedOffering
    }

    console.log(selectedOffering)

    if (this.descriptionInput) {
      this.descriptionInput.value = `${selectedOffering.name}: ${selectedOffering.description}`
    }

    this._setPriceAndCost(offering, event)
  }

  _setPersistedOffering() {
    this.persistedOffering = {
      id: this._getIdFromGlobalId(),
      price_cents: parseInt(this.priceInput.value) * 100,
      cost_cents: parseInt(this.costInput.value) * 100
    }
  }

  _selectedIsPersisted() {
    return this._getIdFromGlobalId() == this.persistedOffering?.id
  }

  _findOfferingBy(selectedOfferingId) {
    return this.defaultListValue.find(({ id: offeringId }) => {
      return offeringId === selectedOfferingId
    })
  }

  _getIdFromGlobalId() {
    const globalIdRoot = `gid://midstay-retreats/Offering::${this.offeringTypeValue}/`
    return parseInt(this.offeringInput.value.replace(globalIdRoot, ""))
  }

  _setPriceAndCost(offering, event) {
    if (event) {
      this.priceInput.value = (offering.price_cents / 100).toFixed(2)
      this.costInput.value = (offering.cost_cents / 100).toFixed(2)
    } else {
      this.priceInput.value ||= (offering.price_cents / 100).toFixed(2)
      this.costInput.value ||= (offering.cost_cents / 100).toFixed(2)
    }
  }

  get offeringInput() {
    return this.element.querySelector('[name*="[itemizable_gid]"]')
  }

  get descriptionInput() {
    return this.element.querySelector('[name*="description"]')
  }

  get priceInput() {
    return this.element.querySelector('[name*="price"]')
  }

  get costInput() {
    return this.element.querySelector('[name*="cost"]')
  }
}